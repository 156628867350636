import React from 'react';
import planetImage from 'images/planet.svg';
import AnimatedBackground from 'components/AnimatedBackground'
import { useTranslation } from 'react-i18next';

const ImproveYourself = ({}) => {
  const { t } = useTranslation();
  return (
    <>
      <AnimatedBackground/>
      <div className='flex flex-col lg:flex-row justify-center items-center container mx-auto pt-16 lg:pt-32'>
        <div className='w-4/5 lg:w-2/3 text-center lg:order-2'>
          <h1 className='text-5xl lg:text-7xl font-semibold text-gray-200 lg:mt-8 lg:px-48'>
            {t("main.planet.title")}
          </h1>
          <p className='text-xl font-light text-gray-200 mt-2 lg:mt-8 lg:px-48'>
            {t("main.planet.description")}
          </p>
        </div>
        <img
          className='w-2/3 lg:w-1/3 opacity-50 mt-6 lg:mt-0 transform duration-700 lg:order-1'
          src={planetImage}
        />
      </div>
    </>
  );
}

export default ImproveYourself;
