import React from 'react';
import Guide from 'layouts/Guide'
import GuideBlock from 'layouts/GuideBlock'
import { useTranslation } from 'react-i18next';

import image1 from './images/1.png'
import image2 from './images/2.png'
import image3 from './images/3.png'
import image4 from './images/4.png'
import image5 from './images/5.png'
import image6 from './images/6.png'
import image7 from './images/7.png'
import image8 from './images/8.png'
import image9 from './images/9.png'
import image10 from './images/10.png'

const Ethereum = ({}) => {
  const { t } = useTranslation();
  return (
    <Guide
      header={t("guide.ethereum.header")}
      description={t("guide.ethereum.description")}
    >
      <GuideBlock
        image={image1}
        text={t("guide.ethereum.0")}
      />
      <GuideBlock
        image={image2}
        text={t("guide.ethereum.1")}
      />
      <GuideBlock
        image={image3}
        text={t("guide.ethereum.2")}
      />
      <GuideBlock
        image={image4}
        text={t("guide.ethereum.3")}
      />
      <GuideBlock
        image={image5}
        text={t("guide.ethereum.4")}
      />
      <GuideBlock
        vertical={true}
        image={image6}
        text={t("guide.ethereum.5")}
      />
      <GuideBlock
        vertical={true}
        image={image7}
        text={t("guide.ethereum.6")}
      />
      <GuideBlock
        vertical={true}
        image={image8}
        text={t("guide.ethereum.7")}
      />
      <GuideBlock
        vertical={true}
        image={image9}
        text={t("guide.ethereum.8")}
      />
      <GuideBlock
        vertical={true}
        image={image10}
        text={t("guide.ethereum.9")}
      />
    </Guide>
  );
}

export default Ethereum;
