import React from 'react';

const Item = ({name, to}) => {
  return (
    <p>
      <a
        className='text-3xl font-light text-gray-100'
        href={to}
      >
        {name}
      </a>
    </p>
  );
}

export default Item;
