import React, {useState} from 'react';
import contactImage from 'images/contact.svg';
import Input from 'components/Input'
import Textarea from 'components/Textarea'
import Button from 'components/Button'
import AnimatedBackground from 'components/AnimatedBackground'
import emailjs from 'emailjs-com';
import alert from 'helpers/alert'
import { useTranslation } from 'react-i18next';

const ContactUs = ({}) => {

  const { t } = useTranslation();

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  const send = async _ => {
    try {
      const templateParams = {
        name,
        email,
        message
      };
      await emailjs.send(
        'service_yubsc5h',
        'template_3s94s65',
        templateParams,
        'user_GCydygoASGdkEgErunH4q'
      )
      setName('')
      setEmail('')
      setMessage('')
      alert('success', t("main.contactUs.sendMessage"))
    }
    catch(e) {
      alert('error', 'Error')
    }
  }

  return (
    <>
      <AnimatedBackground/>
      <div
        id='contactUs'
        className='flex flex-col items-center py-16 lg:py-32'
      >
        <h1 className='text-5xl font-semibold text-gray-200'>
          {t("main.contactUs.title")}
        </h1>
        <img
          className='opacity-50 mt-8 lg:mt-0'
          src={contactImage}
        />
        <Input
          label={t("main.contactUs.name")}
          value={name}
          handle={setName}
        />
        <Input
          label={t("main.contactUs.email")}
          value={email}
          handle={setEmail}
        />
        <Textarea
          label={t("main.contactUs.message")}
          value={message}
          handle={setMessage}
        />
        <Button
          text={t("main.contactUs.button")}
          handle={_ => send()}
        />
      </div>
    </>
  );
}

export default ContactUs;
