import React from 'react';
import Guide from 'layouts/Guide'
import GuideBlock from 'layouts/GuideBlock'
import { useTranslation } from 'react-i18next';
import alert from 'helpers/alert'

import image1 from './images/1.png'
import image2 from './images/2.png'
//import image3 from './images/3.png'
import image4 from './images/4.png'
import image5 from './images/5.png'
import image6 from './images/6.png'
import image7 from './images/7.png'
import image8 from './images/8.png'
import image9 from './images/9.png'
import image10 from './images/10.png'
import image11 from './images/11.png'
import image12 from './images/12.png'
import image13 from './images/13.png'
import image14 from './images/14.png'
import image15 from './images/15.png'
import image16 from './images/16.png'
import image17 from './images/17.png'
import image18 from './images/18.png'
import image19 from './images/19.png'
import image20 from './images/20.png'
import image21 from './images/21.png'
import image22 from './images/22.png'
import image23 from './images/23.png'
import image24 from './images/24.png'

const Token = ({}) => {
  const { t } = useTranslation();
  return (
    <Guide
      header={t("guide.token.header")}
    >
    <GuideBlock
      image={image1}
      text={
        <>
        {t("guide.token.0.0")}
        <span className='mx-2 hover:opacity-75'>
          <a href='https://uniswap.org/' target="_blank" className='border-b'>
           {t("guide.token.0.1")}
          </a>
        </span>
        {t("guide.token.0.2")}
        </>
      }
    />
    <GuideBlock
      image={image2}
      text={t("guide.token.1")}
    />
    <GuideBlock
      image={image4}
      text={t("guide.token.2")}
    />
    <GuideBlock
      image={image5}
      text={t("guide.token.3")}
    />
    <GuideBlock
      image={image6}
      text={t("guide.token.4")}
    />
    <GuideBlock
      image={image7}
      text={t("guide.token.5")}
    />
    <GuideBlock
      image={image8}
      text={t("guide.token.6")}
    />
    <GuideBlock
      image={image9}
      text={t("guide.token.7")}
    />
    <GuideBlock
      image={image10}
      text={
        <>
        {t("guide.token.8.0")}
        <span className='mx-2 hover:opacity-75'>
          <span
            className='border-b cursor-pointer'
            onClick={() => {
              navigator.clipboard.writeText('0x299e82279A5F30F9D8b747Bc41a7EEb405a72Dd8')
              alert('success', t("guide.token.8.copyAddress"))
            }}
          >
           {t("guide.token.8.1")}
          </span>
        </span>
        </>
      }
    />
    <GuideBlock
      image={image11}
      text={t("guide.token.9")}
    />
    <GuideBlock
      image={image12}
      text={t("guide.token.10")}
    />
    <GuideBlock
      image={image13}
      text={t("guide.token.11")}
    />
    <GuideBlock
      image={image14}
      text={t("guide.token.12")}
    />
    <GuideBlock
      image={image15}
      text={t("guide.token.13")}
    />
    <GuideBlock
      image={image16}
      text={t("guide.token.14")}
    />
    <GuideBlock
      image={image17}
      text={t("guide.token.15")}
    />
    <GuideBlock
      image={image18}
      text={t("guide.token.16")}
    />
    <GuideBlock
      image={image19}
      text={t("guide.token.17")}
    />
    <GuideBlock
      image={image20}
      text={t("guide.token.18")}
    />
    <GuideBlock
      vertical={true}
      image={image21}
      text={t("guide.token.19")}
    />
    <GuideBlock
      vertical={true}
      image={image22}
      text={t("guide.token.20")}
    />
    <GuideBlock
      vertical={true}
      image={image23}
      text={t("guide.token.21")}
    />
    <GuideBlock
      vertical={true}
      image={image24}
      text={t("guide.token.22")}
    />
    </Guide>
  );
}

export default Token;
