import React from 'react';
import Header from 'components/Header'
import { BiArrowBack as BackIcon } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';

const Guide = ({header, description, children}) => {
  const { t } = useTranslation();
  return (
    <div className='container px-4 mx-auto py-12'>
      <a
        className='inline-flex items-center text-xl text-gray-300 transform duration-300 hover:scale-125'
        href='/#forBeginners'
      >
        <BackIcon/>
        <span className='ml-2 '>
          {t("guide.goBackButton")}
        </span>
      </a>
      <h1 className='text-5xl font-semibold text-gray-200 text-center'>
        {header}
      </h1>
      <p className='text-xl lg:text-2xl font-light text-gray-200 text-center mt-2 lg:px-56'>
        {description}
      </p>
      <div className='flex flex-col justify-center items-center'>
        <div className='w-full lg:w-2/3'>
          {children}
        </div>
      </div>
    </div>
  );
}

export default Guide;
