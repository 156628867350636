import React from 'react';
import logo from 'images/logo.svg';
import SwitchTheme from './../SwitchTheme'
import SwitchLanguage from './../SwitchLanguage'
import { useTranslation } from 'react-i18next';

import Item from './Item'

const Large = ({}) => {

  const { t } = useTranslation();

  return (
    <div className='hidden lg:flex flex-row py-6 px-6'>
      <div className='w-1/4 inline-flex justify-center items-center px-16 rounded-lg'>
        <img
          className='h-24'
          src={logo}
        />
        <h1 className='text-2xl text-gray-200 font-semibold'>
          BZToken
        </h1>
      </div>
      <div className='w-3/4 inline-flex justify-evenly items-center'>
        <Item
          name={t("header.home")}
          to='/#home'
        />
        <Item
          name={t("header.about")}
          to='/#about'
        />
        <Item
          name={t("header.faq")}
          to='/#faq'
        />
        <Item
          name={t("header.beginners")}
          to='/#forBeginners'
        />
        <Item
          name={t("header.contact")}
          to='/#contactUs'
        />
        <div className='flex flex-row justify-center items-center space-x-4'>
          <SwitchLanguage/>
          <SwitchTheme/>
        </div>
      </div>
    </div>
  );
}

export default Large;
