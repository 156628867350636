import React from 'react';
import Card from './Card'
import { BsLockFill as LockIcon } from 'react-icons/bs';
import { BsBarChartFill as GrowthIcon } from 'react-icons/bs';
import { FaStar as StarIcon } from 'react-icons/fa';
import { FaGlobeAmericas as PlanetIcon } from 'react-icons/fa';
import { GiToken as TokenIcon } from 'react-icons/gi';
import { IoRocketSharp as RocketIcon } from 'react-icons/io5';
import { BsFillPeopleFill as TeamIcon } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';

const Grid = ({}) => {

  const { t } = useTranslation();

  return (
    <div
      className='container px-4 mx-auto grid grid-cols-1 lg:grid-cols-3 gap-6 py-16'
      id='about'
    >
      <Card
        icon={<TeamIcon/>}
        title={t("main.grid.0.title")}
        text={t("main.grid.0.description")}
      />
      <Card
        icon={<PlanetIcon/>}
        title={t("main.grid.1.title")}
        text={t("main.grid.1.description")}
      />
      <Card
        icon={<TokenIcon/>}
        title={t("main.grid.2.title")}
        text={t("main.grid.2.description")}
      />
      <Card
        icon={<LockIcon/>}
        title={t("main.grid.3.title")}
        text={t("main.grid.3.description")}
      />
      <Card
        icon={<RocketIcon/>}
        title={t("main.grid.4.title")}
        text={t("main.grid.4.description")}
      />
      <Card
        icon={<StarIcon/>}
        title={t("main.grid.5.title")}
        text={t("main.grid.5.description")}
      />
    </div>
  );
}

export default Grid;
