import Swal from 'sweetalert2'

const Toast = Swal.mixin({
  toast: true,
  position: 'bottom',
  showConfirmButton: false,
  //width: '12vw',
  timer: 2500,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

const alertTost = (icon, title) => {
  return new Promise(async resolve => {
    await Toast.fire({
      icon,
      title
    })
    resolve()
  })
}

export default alertTost
