import React from 'react';
import Small from './Small'
import Large from './Large'

const Header = ({}) => {

  return (
    <header>
      <Small/>
      <Large/>
    </header>
  );
}

export default Header;
