import React from 'react';
import Item from './Item'
import { useTranslation } from 'react-i18next';

const Faq = ({}) => {
  const { t } = useTranslation();
  return (
    <div
      className='flex flex-col items-center px-4 pt-16 lg:pt-32'
      id='faq'
    >
      <h1 className='text-5xl font-semibold text-gray-200'>FAQ</h1>
      <Item
        question={t("main.faq.0.title")}
        answer={t("main.faq.0.description")}
      />
      <Item
        question={t("main.faq.1.title")}
        answer={
          <>
            <p>{t("main.faq.1.description.0")}</p>
            <a
              target="_blank"
              href='https://beloezerkalo.online/'
              className='hover:opacity-50 border-b border-purple-800'
            >
              https://beloezerkalo.online/
            </a>
            <p className='mt-2'>{t("main.faq.1.description.1")}</p>
          </>
        }
      />
      <Item
        question={t("main.faq.2.title")}
        answer={
          <>
            <a
              target="_blank"
              href='https://etherscan.io/address/0x299e82279A5F30F9D8b747Bc41a7EEb405a72Dd8'
              className='hover:opacity-50 border-b border-purple-800'
            >
              {t("main.faq.2.description")}
            </a>
          </>
        }
      />
      <Item
        question={t("main.faq.3.title")}
        answer={t("main.faq.3.description")}
      />
    </div>
  );
}

export default Faq;


/*<Item
  question={'Where can I see information about this token?'}
  answer={
    <a target="_blank" href='https://etherscan.io/address/0x299e82279A5F30F9D8b747Bc41a7EEb405a72Dd8'>https://etherscan.io/address/0x299e82279A5F30F9D8b747Bc41a7EEb405a72Dd8</a>
  }
/>*/
