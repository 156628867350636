import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from './Card'

import metamask from 'images/metamask.svg';
import ethereum from 'images/ethereum.svg';
import uniswap from 'images/uniswap.svg';

const ForBeginners = ({}) => {
  const { t } = useTranslation();
  return (
    <div
      className='container px-4 mx-auto flex flex-col items-center mt-32'
      id='forBeginners'
    >
      <h1 className='text-5xl font-semibold text-gray-200'>
        {t("main.forBeginners.title")}
      </h1>
      <div className='grid grid-cols-1 lg:grid-cols-3 gap-6 mt-10'>
        <Card
          image={metamask}
          title={t("main.forBeginners.metamask")}
          to='/guide-metamask'
        />
        <Card
          image={ethereum}
          title={t("main.forBeginners.ethereum")}
          to='/guide-ethereum'
        />
        <Card
          image={uniswap}
          title={t("main.forBeginners.token")}
          to='/guide-token'
        />
      </div>
    </div>
  );
}

export default ForBeginners;
