import React from "react";
import Switch from "react-switch";
import { useTranslation } from 'react-i18next';


const SwitchLanguage = _ => {

  const { i18n } = useTranslation();

  const [ru, setRu] = React.useState(i18n.language === 'ru')

  const handle = async _ => {
    const language = ru ? 'en' : 'ru'
    await i18n.changeLanguage(language)
    setRu(!ru)
  }

  return(
    <Switch
      className='bg-pink-300 dark:bg-pink-900'
      onChange={_ => handle()}
      checked={ru}
      onColor={'text-pink-300 dark:text-pink-900'}
      checkedIcon={
        <div className='flex justify-center items-center w-full h-full font-medium text-white'>
          RU
        </div>
      }
      offColor={'text-pink-300 dark:text-pink-900'}
      uncheckedIcon={
        <div className='flex justify-center items-center w-full h-full font-medium text-white'>
          EN
        </div>
      }
    />
  )
}

export default SwitchLanguage
