import React from 'react';
import Particles from 'react-particles-js';


const AnimatedBackground= _ => {
  return <Particles style={{position: 'absolute', zIndex: -1}}
    params={{
      "particles": {
          "number": {
              "value": window.screen.width / 50
          },
          "size": {
              "value": 2
          }
      },
      "move": {
          "direction": "right",
          "speed": 10
      },
    }}
 />
}

export default AnimatedBackground;
