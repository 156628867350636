import React from 'react';

const GuideBlock = ({image, text, vertical}) => {
  return (
    <div className='w-full mt-16'>
      {image &&
        <div className='flex flex-row justify-center bg-gradient-to-r from-purple-400 to-pink-300 dark:from-purple-800 dark:to-pink-700 rounded'>
          <img
            src={image}
            className={`shadow-2xl ${vertical ? 'lg:w-1/3' : ''}`}
          />
        </div>
      }
      {text &&
        <p className='text-gray-200 text-xl font-light mt-2'>
          {text}
        </p>
      }
    </div>
  );
}

export default GuideBlock;
