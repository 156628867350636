import React from 'react';

const Item = ({question, answer}) => {

  const [open, setOpen] = React.useState(false)

  return (
    <div
      className='w-full bg-gray-200 shadow-2xl rounded-3xl py-4 px-8 mt-8 w-4/5 lg:w-2/5 cursor-pointer'
      onClick={_ => setOpen(!open)}
    >
      <h className='text-purple-800 opacity-75 text-2xl font-light'>{question}</h>
      {open &&
        <div className='text-purple-800 opacity-75 lg:text-xl font-light mt-2'>
          {answer}
        </div>
      }
    </div>
  );
}

export default Item;
