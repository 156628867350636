import React from 'react';
import Particles from 'react-particles-js';


const AnimatedBackgroundNightSky= _ => {
  return <Particles style={{position: 'absolute', zIndex: -1}}
    params={{
      "particles": {
          "number": {
              "value": 90,
              "density": {
                  "enable": true,
                  "value_area": 1500
              }
          },
          "line_linked": {
              "enable": true,
              "opacity": 0.02
          },
          "move": {
              "direction": "right",
              "speed": 0.50
          },
          "size": {
              "value": 3
          },
          "opacity": {
              "anim": {
                  "enable": true,
                  "speed": 1.2,
                  "opacity_min": 0.05
              }
          }
      },
      "interactivity": {
          "events": {
              "onclick": {
                  "enable": true,
                  "mode": "push"
              }
          },
          "modes": {
              "push": {
                  "particles_nb": 1
              }
          }
      },
      "retina_detect": true
    }}
  />
}

export default AnimatedBackgroundNightSky;
