import React from 'react';
import Guide from 'layouts/Guide'
import GuideBlock from 'layouts/GuideBlock'
import { useTranslation } from 'react-i18next';

import image1 from './images/1.png'
import image2 from './images/2.png'
import image3 from './images/3.png'
import image4 from './images/4.png'
import image5 from './images/5.png'
import image6 from './images/6.png'
import image7 from './images/7.png'
import image8 from './images/8.png'
import image9 from './images/9.png'
import image10 from './images/10.png'
import image11 from './images/11.png'
import image12 from './images/12.png'
import image13 from './images/13.png'

const Metamask = ({}) => {
  const { t } = useTranslation();
  return (
    <Guide
      header={t("guide.metamsk.header")}
      description={t("guide.metamsk.description")}
    >
      <GuideBlock
      />
      <GuideBlock
        image={image1}
        text={
          <>
          {t("guide.metamsk.0.0")}
          <span className='mx-2 hover:opacity-75'>
            <a href='https://metamask.io/download.html' target="_blank" className='border-b'>
             {t("guide.metamsk.0.1")}
            </a>
          </span>
          {t("guide.metamsk.0.2")}
          </>
        }
      />
      <GuideBlock
        image={image2}
        text={t("guide.metamsk.1")}
      />
      <GuideBlock
        image={image3}
        text={t("guide.metamsk.2")}
      />
      <GuideBlock
        image={image4}
        text={t("guide.metamsk.3")}
      />
      <GuideBlock
        image={image5}
        text={t("guide.metamsk.4")}
      />
      <GuideBlock
        image={image6}
        text={t("guide.metamsk.5")}
      />
      <GuideBlock
        image={image7}
        text={t("guide.metamsk.6")}
      />
      <GuideBlock
        image={image8}
        text={t("guide.metamsk.7")}
      />
      <GuideBlock
        image={image9}
        text={t("guide.metamsk.8")}
      />
      <GuideBlock
        image={image10}
        text={t("guide.metamsk.9")}
      />
      <GuideBlock
        image={image11}
        text={t("guide.metamsk.10")}
      />
      <GuideBlock
        image={image12}
        text={t("guide.metamsk.11")}
      />
      <GuideBlock
        image={image13}
        text={t("guide.metamsk.12")}
      />
      <GuideBlock
        text={t("guide.metamsk.13")}
      />
    </Guide>
  );
}

export default Metamask;
