import React from 'react';

const Input = ({value, handle, placeholder, label}) => {
  return (
    <div
      className='w-4/5 lg:w-1/3 flex flex-col mt-8'
    >
      <label
        className='text-gray-200 font-medium'
      >
        {label}
      </label>
      <input
        className='border-2 border-gray-200 rounded-xl mt-2 py-2 px-4 text-gray-100'
        type='text'
        style={{background: 'transparent'}}
        value={value}
        onChange={e => handle(e.target.value)}
        placeholder={placeholder}
      />
    </div>
  );
}

export default Input;
