import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Header from 'components/Header'
import Main from 'screens/Main'
import Metamask from 'screens/Guides/Metamask'
import Ethereum from 'screens/Guides/Ethereum'
import Token from 'screens/Guides/Token'

const App = ({}) => {
  return (
    <>
      <Header/>
      <Switch>
        <Route exact path='/' render={() => <Main/>} />
        <Route exact path='/guide-metamask' render={() => <Metamask/>} />
        <Route exact path='/guide-ethereum' render={() => <Ethereum/>} />
        <Route exact path='/guide-token' render={() => <Token/>} />
      </Switch>
    </>
  );
}

export default App;
