import React from 'react';

const Card = ({icon, title, text}) => {
  return (
    <div
      className='flex flex-col justify-center items-center bg-gray-200 shadow-2xl rounded-3xl py-6 px-6 text-center'
    >
      <div className='text-6xl text-purple-500 opacity-75'>
        {icon}
      </div>
      <h className='text-purple-800 opacity-75 text-3xl font-light mt-2 text-center'>{title}</h>
      <p className='text-purple-800 opacity-75 text-xl font-light mt-2'>{text}</p>
    </div>
  );
}

export default Card;
