import React from 'react';
import {motion} from 'framer-motion'

const Card = ({image, title, to}) => {

  return (
    <motion.a
      whileHover={{
        scale: 1.1,
        rotate: 3,
        transition: { duration: 0.4 },
      }}
      className='flex flex-col justify-center items-center bg-gray-200 shadow-2xl rounded-3xl py-6 px-6 cursor-pointer'
      href={to}
    >
      <img
        className='h-36'
        src={image}
      />
      <h className='text-purple-800 opacity-75 text-2xl font-light mt-2 text-center'>{title}</h>
    </motion.a>
  );
}

export default Card;
