import React from 'react';
import { slide as Menu } from 'react-burger-menu'
import logo from 'images/logo.svg';
import { useTranslation } from 'react-i18next';

import Item from './Item'
import SwitchTheme from './../SwitchTheme'
import SwitchLanguage from './../SwitchLanguage'

const Small = ({}) => {

  const { t } = useTranslation();

  return (
    <div className='lg:hidden pb-20'>
      <div className='flex flex-row items-center mt-5 ml-4 absolute'>
        <img
          className='h-16'
          src={logo}
        />
        <h1 className='text-2xl text-gray-200 font-semibold'>
          BZToken
        </h1>
      </div>
      <Menu
        right
        styles={styles}
        width={'200px'}
      >
        <div className='w-full text-center py-4'>
          <SwitchTheme/>
        </div>
        <div className='w-full text-center py-4'>
          <SwitchLanguage/>
        </div>
        <Item
          name={t("header.home")}
          to='/#home'
        />
        <Item
          name={t("header.about")}
          to='/#about'
        />
        <Item
          name={t("header.faq")}
          to='/#faq'
        />
        <Item
          name={t("header.beginners")}
          to='/#forBeginners'
        />
        <Item
          name={t("header.contact")}
          to='/#contactUs'
        />
      </Menu>
    </div>
  );
}

export default Small;


var styles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '36px',
    height: '30px',
    right: '36px',
    top: '36px'
  },
  bmBurgerBars: {
    background: '#F3F4F6'
  },
  bmBurgerBarsHover: {
    background: '#a90000'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {
    background: '#F3F4F6'
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%'
  },
  bmMenu: {
    background: '#A78BFA',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em'
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em'
  },
  bmItem: {
    display: 'inline-block'
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)'
  }
}
