import React from "react";
import Switch from "react-switch";
import { IoMdSunny as SunIcon } from 'react-icons/io';
import { BsMoon as MoonIcon } from 'react-icons/bs';

const root = window.document.documentElement

const SwitchTheme = _ => {

  const [darkMode, set] = React.useState(root.classList.value ? true : false)

  const handle = _ => {
    if(!darkMode) root.classList.add('dark')
    else root.classList.remove('dark')
    set(!darkMode)
  }

  return(
    <Switch
      onChange={_ => handle()}
      checked={root.classList.value ? true : false}
      onColor={'#831843'}
      checkedIcon={
        <div className='flex justify-center items-center w-full h-full'>
          <MoonIcon className='text-yellow-400 text-xl'/>
        </div>
      }
      offColor={'#F9A8D4'}
      uncheckedIcon={
        <div className='flex justify-center items-center w-full h-full'>
          <SunIcon className='text-yellow-100 text-xl'/>
        </div>
      }
    />
  )
}

export default SwitchTheme
