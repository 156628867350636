import React from 'react';
import mountainsImage from 'images/mountains.svg';
import AnimatedBackgroundNightSky from 'components/AnimatedBackgroundNightSky'
import {motion} from 'framer-motion'
import { useTranslation } from 'react-i18next';

const Home = ({}) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <AnimatedBackgroundNightSky/>
      <div
        className='w-full flex flex-col items-center'
        id='home'
      >
        <div className='lg:w-1/2 text-center lg:py-16 px-2'>
          <h1 className='text-8xl lg:text-9xl font-semibold text-gray-200'>
            {t("main.home.title")}
          </h1>
          <p className='text-2xl font-light text-gray-200 mt-8'>
            {t("main.home.description")}
          </p>
        </div>
        <motion.img
        initial={{opacity: 0}}
        animate={{opacity: 0.5}}
        transition={{ duration: 1}}
          className=' pt-16 lg:pt-0'
          src={mountainsImage}
        />
      </div>
    </>
  );
}


export default Home;
