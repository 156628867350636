import React from 'react';
import Home from './Home'
import Grid from './Grid'
import Human from './Human'
import Planet from './Planet'
import Faq from './Faq'
import ForBeginners from './ForBeginners'
import ContactUs from './ContactUs'

const Main = ({}) => {
  return (
    <div>
      <Home/>
      <Grid/>
      <Human/>
      <Planet/>
      <Faq/>
      <ForBeginners/>
      <ContactUs/>
    </div>
  );
}

export default Main;
