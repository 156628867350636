import React from 'react';

const Button = ({text, handle}) => {
  return (
    <button
      className='w-4/5 lg:w-1/3 mt-8 py-2 my-6 bg-gray-200 rounded-xl text-purple-800 text-opacity-75 text-lg font-medium shadow-md transform duration-700 hover:scale-105'
      onClick={_ => handle()}
    >
      {text}
    </button>
  );
}

export default Button;
