import React from 'react';
import {motion} from 'framer-motion'

const Item = ({name, to}) => {
  return (
    <motion.a
      initial={{y: '-10vh', opacity: 0}}
      animate={{y: 0, opacity: 1}}
      transition={{ duration: 1}}
      whileHover={{
        scale: 1.2,
        transition: { duration: 0.4 },
      }}
      className='text-2xl font-light text-gray-300'
      href={to}
    >
      {name}
    </motion.a>
  );
}

export default Item;
